.preloader {
  min-width: 100px;
  min-height: 100px;
}

.preloader hr {
  border: 0;
  margin: 0;
  width: 50%;
  height: 50%;
  position: absolute;
  border-radius: 10px;
  animation: spin 6s ease infinite
}

.preloader :first-child {
  background: #1C434F;
  animation-delay: -4s
}

.preloader :nth-child(2) {
  background: #D08B7E;
  animation-delay: -2s
}

.preloader :nth-child(3) {
  background: #F9E69A;
}

@keyframes spin {
  0%, 100% {
    transform: translate(0)
  }
  25% {
    transform: translate(130%)
  }
  50% {
    transform: translate(130%, 130%)
  }
  75% {
    transform: translate(0, 130%)
  }
}
